import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your Firebase configuration object
const firebaseConfig = {
    apiKey: "AIzaSyDSrJB2WoctjZ11-ex141lDP-zPBcUK910",
    authDomain: "el-malky.firebaseapp.com",
    projectId: "el-malky",
    storageBucket: "el-malky.appspot.com",
    messagingSenderId: "888151560641",
    appId: "1:888151560641:web:81750b2f56af12bcb3df40",
    measurementId: "G-96989K61KK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firestore instance
const db = getFirestore(app);

// Authentication instance
const auth = getAuth(app);

// Storage instance
const storage = getStorage(app);

export { db, auth, storage };
