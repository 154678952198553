import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import { MdLocalPhone } from "react-icons/md";
import { Link as LinkS } from "react-scroll";

function Icon() {

    const [iShow, setiShow] = useState(false)
    const ShowIcon = () => {
        if (window.scrollY >= 400) {
            setiShow(true)
        } else {
            setiShow(false)
        }
    }

    window.addEventListener('scroll', ShowIcon)

    return (
        <div className="sticky-i">
            <LinkS
                data-aos-offset="0" to="showcase" className={iShow ? ' S-btn ' : 'S-btn hidden'}>
                <IoIosArrowUp />
            </LinkS>

            <a
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="600"
                className="S-btn"
                href="tel:+966551155707"
                target="_blank"
                rel="noreferrer"
            >
                <MdLocalPhone />
            </a>
            <a
                data-aos="fade-left"
                data-aos-easing="linear"
                data-aos-duration="600"
                className="S-btn"
                href="https://api.whatsapp.com/send?phone=966551155707"
                target="_blank"
                rel="noreferrer"
            >
                <FaWhatsapp />
            </a>
        </div>
    )
}

export default Icon