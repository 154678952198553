import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import './Rblog.css';
import BlogCard from '../Blogcard/BlogCard';
import { useBlogs } from '../../Context/BlogContext';
import { Link } from 'react-router-dom';


const Rblog = () => {
    const blogs = useBlogs();
    const numberOfItems = Math.min(blogs.length, 9);

    return (
        <div id='Rblog'>
            <div className='Rblog'>
                <div className='Rblog-head' data-aos="fade-left" data-aos-offset="250" data-aos-duration="800">
                    <Link to="/blog"> &lt; عرض المزيد</Link>
                    <h5>احدث المقالات</h5>
                </div>
                <div className='Rblog-container' data-aos="fade-right" data-aos-offset="250" data-aos-duration="800">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}

                        scrollbar={{ draggable: true }} // Enable draggable scrollbar
                        modules={[Scrollbar]} // Import necessary modules
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                            1366: {
                                slidesPerView: 4,
                            },

                            1600: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        {blogs.length > 0 ? (
                            blogs.slice(0, numberOfItems).map((blog) => (
                                <SwiperSlide key={blog.id}>
                                    <BlogCard
                                        id={blog.id}
                                        Pimg={blog.imageUrl}
                                        title={blog.title}
                                        content={blog.content}
                                        date={blog.timestamp ? new Date(blog.timestamp).toLocaleDateString() : 'Unknown date'}
                                    />
                                </SwiperSlide>
                            ))
                        ) : (
                            <p>لا يوجد مدونات</p>
                        )}
                        {/* <br /> */}
                        <br />
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Rblog;
