import React, { useState, useEffect } from 'react';
import { auth, db, storage } from '../../firebaseConfig';  // Import Firestore db
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, deleteDoc, collection, getDocs, query } from 'firebase/firestore';  // Import Firestore functions
import { ref, deleteObject } from 'firebase/storage';  // Import deleteObject and ref
import { useNavigate, Link } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';
import './ExistingBlogsPage.css';
import AdBlogCard from './AdBlogCard';

const ExistingBlogsPage = () => {
    const [blogs, setBlogs] = useState([]);  // State to manage blogs
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;
    const navigate = useNavigate();

    // Fetch blogs from Firestore when the component mounts
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const q = query(collection(db, 'blogs'));
                const querySnapshot = await getDocs(q);
                const blogsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setBlogs(blogsData);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    // Check authentication state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate('/login');
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                navigate('/login');
            })
            .catch((error) => {
                console.error('Error signing out:', error);
            });
    };

    const deleteBlog = async (id, imageUrl) => {
        const confirmed = window.confirm(`Are you sure you want to delete this blog? \n هل انت متأكد من حذف المدونة ؟`);
        if (confirmed) {
            try {
                // Create a reference to the blog document
                const blogRef = doc(db, 'blogs', id);

                // Create a reference to the blog's image in Firebase Storage
                const imageRef = ref(storage, imageUrl);  // Assuming `imageUrl` contains the path to the image

                // Delete the blog document from Firestore
                await deleteDoc(blogRef);

                // Delete the image file from Firebase Storage
                await deleteObject(imageRef);

                // Update state by filtering out the deleted blog
                setBlogs(prevBlogs => prevBlogs.filter(blog => blog.id !== id));

                console.log('Blog and image deleted:', id);
            } catch (error) {
                console.error('Error deleting blog and image:', error);
            }
        }
    };

    const filteredBlogs = blogs.filter(blog =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        blog.content.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredBlogs.length / itemsPerPage);
    const paginatedBlogs = filteredBlogs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePaginationClick = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const renderPagination = () => {
        const paginationItems = [];
        paginationItems.push(
            <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePaginationClick(1)}>
                1
            </Pagination.Item>
        );

        if (currentPage > 2) {
            paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        if (currentPage > 1 && currentPage <= totalPages - 1) {
            paginationItems.push(
                <Pagination.Item key={currentPage} active onClick={() => handlePaginationClick(currentPage)}>
                    {currentPage}
                </Pagination.Item>
            );
        }

        if (currentPage < totalPages - 1) {
            paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        if (totalPages > 1) {
            paginationItems.push(
                <Pagination.Item key={totalPages} active={currentPage === totalPages} onClick={() => handlePaginationClick(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return paginationItems;
    };

    return (
        <div id='Eblogs'>
            <div className='Eblogs'>
                <div className='Eblog-head'>
                    <div className='Eblog-head1'>
                        <button onClick={handleLogout} className="logout-button">تسجيل خروج</button>
                        <Link to='/' dir='rtl'>الرئيسية</Link>
                        <Link to='/admin' dir='rtl'>اضف مدونة +</Link>
                    </div>
                    <div className='Eblog-head2'>
                        <h3>مدونات الموقع</h3>
                        <input
                            type="text"
                            placeholder="بحث"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="search-bar"
                        />
                    </div>
                </div>
                <div className='admin-blog-container'>
                    <div className='admin-card-container'>
                        {paginatedBlogs.map((blog) => (
                            <div className='admin-Bcard' key={blog.id}>
                                <AdBlogCard
                                    key={blog.id}
                                    id={blog.id}
                                    Pimg={blog.imageUrl}
                                    title={blog.title}
                                    content={blog.content}
                                    date={
                                        blog.timestamp && blog.timestamp.toDate
                                            ? new Date(blog.timestamp.toDate()).toLocaleDateString()
                                            : 'Unknown date'
                                    }

                                    onClick={() => deleteBlog(blog.id, blog.imageUrl)}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {totalPages > 1 && (
                <div className='PagiNation'>
                    <Pagination>
                        <Pagination.First onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1} />
                        {renderPagination()}
                        <Pagination.Last onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === totalPages} />
                    </Pagination>
                </div>
            )}
        </div>
    );
};

export default ExistingBlogsPage;
