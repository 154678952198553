import React, { useEffect, useRef, useState } from 'react';
import './Nav.css';
import Navbar from 'react-bootstrap/Navbar';
import { BiMenuAltLeft, BiX } from "react-icons/bi";
import Nlogo from '../../assets/nav-logo.png';
import { Link as LinkS } from 'react-scroll';
import classNames from 'classnames';
import { FaInstagram } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import { SlSocialLinkedin } from "react-icons/sl";
import { Link } from 'react-router-dom';
import { LiaSnapchat } from 'react-icons/lia';
import { FaXTwitter } from 'react-icons/fa6';

function Nav() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isNavBg, setIsNavBg] = useState(false);
    const [activeNav, setActiveNav] = useState('header');
    const navContainerRef = useRef(null);

    const toggleMenu = () => setIsMenuOpen(prev => !prev);
    const closeMenu = () => setIsMenuOpen(false);

    const changeBg = () => {
        setIsNavBg(window.scrollY >= 90);
    };

    const handleClickOutside = (event) => {
        if (navContainerRef.current && !navContainerRef.current.contains(event.target)) {
            closeMenu();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBg);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', changeBg);
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['showcase', 'about', 'serv', 'contact', 'Rblog'];
            let currentSection = 'header';

            for (let i = 0; i < sections.length; i++) {
                const section = document.getElementById(sections[i]);
                if (section) {
                    const sectionTop = section.getBoundingClientRect().top + window.scrollY;
                    const sectionBottom = sectionTop + section.offsetHeight;

                    if (window.pageYOffset >= sectionTop - 500 && window.pageYOffset < sectionBottom - 100) {
                        currentSection = `#${sections[i]}`;
                        break;
                    }
                }
            }

            setActiveNav(currentSection);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id='navbar'>
            <Navbar fixed="top" expand="lg" className={classNames('navbar', { 'navbar-bg': isNavBg })}>
                <div className={classNames('menu', { 'menu-bg': isNavBg })}>
                    <i><BiMenuAltLeft onClick={toggleMenu} /></i>
                </div>
                <div className="nav-container" ref={navContainerRef}>
                    <div dir='rtl'
                        className={classNames('topnav', { 'Svisible': isMenuOpen, 'closed': !isMenuOpen })}
                        id='nav-container'
                    >
                        <div className="close-btn" onClick={(e) => { e.stopPropagation(); closeMenu(); }}>
                            <BiX />
                        </div>
                        <LinkS to="showcase" className='Hactive'>الرئيسية</LinkS>
                        <LinkS to="about" className={activeNav === '#about' ? 'Nactive touch' : 'touch'}>من نحن</LinkS>
                        <LinkS to="serv" className={activeNav === '#serv' ? 'Nactive touch' : 'touch'}>خدماتنا</LinkS>
                        <LinkS to="contact" className={activeNav === '#contact' ? 'Nactive touch' : 'touch'}>تواصل</LinkS>
                        <Link to="/blog" style={{ color: "#CAA457" }} className={activeNav === '#Rblog' ? 'Nactive touch' : 'touch'}>المدونة</Link>

                        <div className='nave-links' dir='ltr'>
                            <a target="_blank"
                                rel="noreferrer" href="https://www.google.com/maps?ll=21.613534,39.154892&z=16&t=m&hl=en&gl=EG&mapclient=embed&cid=14346717577956034144"><i className='nav-Linkbt'><GrMapLocation /></i></a>
                            <a target="_blank"
                                rel="noreferrer" href="https://www.linkedin.com/in/legal-company-378620328/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><i className='nav-Linkbt'><SlSocialLinkedin /></i></a>
                            <a target="_blank"
                                rel="noreferrer" href="https://snapchat.com/t/W0NxoL9c"><i className='nav-Linkbt'><LiaSnapchat /></i></a>
                            <a target="_blank"
                                rel="noreferrer" href="https://x.com/ialawcom?s=11"><i className='nav-Linkbt'><FaXTwitter /></i></a>
                            <a target="_blank"
                                rel="noreferrer" href="https://www.instagram.com/legalcompany2024?igsh=M3I4bjYxcjQycGdy&utm_source=qr"><i className='nav-Linkbt'><FaInstagram /></i></a>
                        </div>
                    </div>
                    <Navbar.Brand href="/" className='logo'>
                        <img
                            data-aos="fade-right"
                            data-aos-easing="ease"
                            data-aos-duration="1000"
                            data-aos-offset="0" src={Nlogo} alt="Nav Logo" />
                    </Navbar.Brand>
                </div>
            </Navbar>
        </section>
    );
}

export default Nav;
