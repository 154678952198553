import React from "react";
import "./About.css";

function About() {


  return (

    <div>
      <section id="about">
        <div className="sec-border"
          data-aos="fade-left"
          data-aos-offset="150"
          data-aos-duration="800">
          <div className="about-container">
            <h1 data-aos="fade-right"><span>من نحن</span></h1>
            <div
              className="text-container">
              <div style={{
                display: "flex",
                justifyContent: "center"
              }}
                data-aos-offset="220"
                data-aos-duration="1300"
                data-aos="fade-right">
                <p dir="rtl">شركة إبراهيم الزهراني وأحمد المالكي للمحاماة والاستشارات القانونية هي شركة قانونية سعودية مهنية تم تأسيسها على معايير المهنية الاحترافية العالية والالتزام بالقيم السامية والأنظمة المرعية. نؤمن بأن القانون هو أساس الاستقرار والنمو في المجتمع ، ونسعى لتحقيق هذا الهدف من خلال تقديم أفضل الخدمات والحلول القانونية الشاملة التي تناسب احتياجات عملائنا ضمن إيطار الاحترافية . مع فريق متخصص من المحامين ذوي الخبرة الواسعة والطويلة في المجال القانوني والشرعي والمعرفة المتعمقة بالقوانين المحلية والدولية، نعمل جاهدين لنكون شركاء موثوقين لعملائنا في مسيرتهم نحو تحقيق النجاح.
                </p>
              </div>
            </div>
            <div className="Mis-vig">
              <div className="Mis-vig-content" data-aos="fade-up"
                data-aos-offset="200"
                data-aos-duration="800">
                <h4>مهمتنا</h4>
                <p>تقديم خدمات قانونية رائدة بأعلى معايير المهنية ، تدعم حقوق عملائنا وتساهم في بناء مجتمع قانوني متين مع الالتزام بأعلى معايير الاحترافية والنزاهة ، مع التركيز على دعم رؤية المملكة 2030 في بناء مجتمع حيوي واقتصاد مزدهر ووطن طموح .</p>
              </div>
              <div className="Mis-vig-content" data-aos="fade-down"
                data-aos-offset="200"
                data-aos-duration="800">
                <h4>رؤيتنا</h4>
                <p>أن نكون الخيار الأول في مجال المحاماة في المملكة العربية السعودية ، والاسهام في تحقيق الرؤية 2030 من خلال تقديم حلول قانونية مبتكرة تدعم النمو الاقتصادي والاجتماعي وتضمن حماية حقوق الأفراد والمنشآت .</p>
              </div>
            </div>

          </div>
        </div>
      </section >
    </div>

  )
}

export default About;
