import React from 'react';
import './BlogCard.css';
import { Link } from 'react-router-dom';

const BlogCard = (props) => {
    // Function to remove <br/>, <h1>, and <h2> tags from the content
    const sanitizeContent = (content) => {
        return content
            .replace(/<h1\b[^>]*>(.*?)<\/h1>/gi, '')  // Remove <h1> tags with any attributes
            .replace(/<h2\b[^>]*>(.*?)<\/h2>/gi, '')  // Remove <h2> tags with any attributes
            .replace(/<img\b[^>]*>/gi, '')            // Remove <img> tags
            .replace(/<br\s*\/?>/gi, '');             // Remove <br> tags
    };


    // Truncate content to show just a snippet (e.g., 100 characters)
    const truncatedContent = props.content.length > 500
        ? props.content.substring(0, 500) + "..."
        : props.content;

    // Sanitize the content before displaying
    const sanitizedContent = sanitizeContent(truncatedContent);

    return (
        <div id='blogcard'>
            <div className='blogcard' dir='rtl'>
                <Link to={`/blog/${props.id}/${props.title}`}>
                    <div className='blogcard__image' id={props.id}>
                        <img src={props.Pimg} alt='img' />
                    </div>
                    <div className='BlogCard__content'>
                        <h6 className='strong'><strong>{props.title}</strong></h6>
                        <h6><small>{props.date}</small></h6>
                        {/* Display sanitized and truncated content */}
                        <p dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default BlogCard;
