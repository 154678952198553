import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { Helmet } from 'react-helmet-async';
import './BlogDetailPage.css';
import { PulseLoader } from 'react-spinners';
import PageNav from '../../components/Nav/PageNav';
import Contact from '../../components/Contact/Contact';
import PageFooter from '../../components/Footer/PageFooter';
import Rblog from '../../components/Rblog/Rblog';

// Function to fetch blog data
const fetchBlog = async (id) => {
    try {
        const storedBlog = sessionStorage.getItem(`blog_${id}`);
        if (storedBlog) {
            return JSON.parse(storedBlog);
        }

        const docRef = doc(db, 'blogs', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const blogData = docSnap.data();
            const addedDate = blogData.addedDate?.toDate() || new Date();
            const blogWithDate = { ...blogData, addedDate };

            sessionStorage.setItem(`blog_${id}`, JSON.stringify(blogWithDate));
            return blogWithDate;
        } else {
            throw new Error('No such document!');
        }
    } catch (error) {
        throw new Error('Error fetching blog:', error);
    }
};

const BlogDetailPage = () => {
    const { id } = useParams();

    const { data: blog, isLoading, isError } = useQuery(['blog', id], () => fetchBlog(id));


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    if (isLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#ffffff", height: "100vh" }}>
                <PulseLoader color="#C79149" />
            </div>
        );
    }

    if (isError || !blog) {
        return <div>تم حذف هذة المدونة</div>;
    }

    return (
        <div id='blog-detail'>
            <Helmet>
                <title>{blog.title}</title>
                <meta name="description" content={blog.content.slice(0, 150)} />
                <meta name="keywords" content={blog.content.slice(0, 150)} />
                <meta property="og:title" content={blog.title} />
                <meta property="og:description" content={blog.content.slice(0, 150)} />
                <meta property="og:image" content={blog.imageUrl} />
                <meta property="og:url" content={`https://i-a-lawco.com/blog/${id}/${blog.title}`} />
                <link rel="canonical" href={`https://i-a-lawco.com/blog/${id}/${blog.title}`} />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="El-Malky" />
            </Helmet>

            <PageNav />
            <div className='blog-detail' dir='rtl'>
                <div className='blog-detail-table'>
                    {blog.imageUrl && <img src={blog.imageUrl} alt={blog.title} style={{ width: '100%', height: 'auto' }} />}
                    <br />
                    <h2><strong>{blog.title}</strong></h2>
                    <br />
                    <h6><small>
                        {blog.addedDate
                            ? new Date(blog.addedDate).toLocaleDateString()
                            : 'Unknown date'}
                    </small></h6>
                    <br />
                    <div className='conentDit' dangerouslySetInnerHTML={{ __html: blog.content }} />
                </div>
                <div className='border-end'></div>
            </div>
            <Rblog />
            <Contact />
            <PageFooter />
        </div>
    );
};

export default BlogDetailPage;
