import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';

// Create the context
const BlogContext = createContext();

// Create a provider component
export const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                // Check if blogs are already in session storage
                const storedBlogs = sessionStorage.getItem('blogs');
                if (storedBlogs) {
                    setBlogs(JSON.parse(storedBlogs));
                } else {
                    // Fetch blogs from Firestore if not in session storage
                    const querySnapshot = await getDocs(collection(db, 'blogs'));
                    const blogsArray = querySnapshot.docs
                        .map((doc) => {
                            const data = doc.data();
                            return {
                                ...data,
                                id: doc.id,
                                timestamp: data.timestamp?.toDate ? data.timestamp.toDate() : new Date(data.timestamp),
                            };
                        })
                        .sort((a, b) => b.timestamp - a.timestamp);

                    // Store blogs in session storage and update state
                    sessionStorage.setItem('blogs', JSON.stringify(blogsArray));
                    setBlogs(blogsArray);
                }
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    return (
        <BlogContext.Provider value={blogs}>
            {children}
        </BlogContext.Provider>
    );
};

// Custom hook to use the BlogContext
export const useBlogs = () => {
    return useContext(BlogContext);
};
