import React, { useState, useEffect } from 'react';
import { auth } from '../../firebaseConfig';
import './LoginPage.css'
import logimg from '../../assets/Flogo.png'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBInput
} from 'mdb-react-ui-kit';
import { onAuthStateChanged } from 'firebase/auth';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/admin');
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/admin');
        } catch (err) {
            setError('Failed to login. Please check your credentials.');
            console.error(err);
        }
    };

    return (
        <div id='Login'>
            <MDBContainer className="my-5">
                <MDBCard className='log-bg'>
                    <MDBRow className='g-0'>
                        <MDBCol md='5'>
                            <Link to="/">
                                <MDBCardImage src={logimg} alt="login form" className='rounded-start w-80' />
                            </Link>
                        </MDBCol>
                        <MDBCol md='6' className='LogForm'>
                            <MDBCardBody className='d-flex flex-column justify-content-center align-items-center w-100'>
                                <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: '1px' }}>تسجيل دخول</h5>

                                <form onSubmit={handleLogin}>
                                    <MDBInput
                                        wrapperClass='mb-4'
                                        placeholder='البريد الالكتروني'
                                        id='formControlLg'
                                        type='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        size="lg"
                                    />
                                    <MDBInput
                                        wrapperClass='mb-4'
                                        placeholder='كلمة المرور'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        id='formControlLg1'
                                        type='password'
                                        size="lg"
                                    />

                                    <MDBBtn className="mb-4 px-5" color='dark' type="submit" size='lg'>تسجيل دخول</MDBBtn>
                                </form>

                                {error && <p className="text-danger">{error}</p>}

                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </div>
    );
};

export default LoginPage;
