import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet-async'
import BlogPage from './Pages/BlogPage/BlogPage.jsx';
import HomePage from './Pages/Homepage.jsx';
import Login from './Pages/loginpage/Loginpage.jsx';
import AdminPage from './Pages/Adminpage/AdminPage.jsx';
import BlogDetailPage from './Pages/BlogDetailPage/BlogDetailPage.jsx';
import ExistingBlogsPage from './Pages/ExistingBlogsPage/ExistingBlogsPage.jsx';

const App = () => {

    useEffect(() => {
        AOS.init({
            // AOS Global settings:
            offset: 0,
            duration: 800,
            easing: 'ease',

        });

        const handleResize = () => {
            AOS.refresh();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div id='main'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Blog</title>
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/blog" element={<BlogPage />} />
                    <Route path="/blog/:id/:title" element={<BlogDetailPage />} />
                    <Route path="/existing-blogs" element={<ExistingBlogsPage />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/admin" element={<AdminPage />} />
                </Routes>
            </Router>

        </div>
    )
}

export default App