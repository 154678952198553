import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App';
import './index.css'
import { BlogProvider } from './Context/BlogContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';

const queryClient = new QueryClient();
ReactDOM.createRoot(document.getElementById('root')).render(
    <QueryClientProvider client={queryClient}>
        <HelmetProvider>
            <BlogProvider>
                <App />
            </BlogProvider>
        </HelmetProvider>
    </QueryClientProvider>
);
