import React, { useState, useEffect } from 'react';
import PageNav from '../../components/Nav/PageNav';
import BlogCard from '../../components/Blogcard/BlogCard';
import { Pagination } from 'react-bootstrap';
import './BlogPage.css';
import { PulseLoader } from 'react-spinners';
import { useBlogs } from '../../Context/BlogContext';
import Contact from '../../components/Contact/Contact';
import PageFooter from '../../components/Footer/PageFooter';
import { Helmet } from 'react-helmet-async';

const BlogPage = () => {
    const blogs = useBlogs();
    const [loading, setLoading] = useState(true);
    const [minLoadingTimeReached, setMinLoadingTimeReached] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;

    const baseUrl = window.location.origin;
    const imageUrl = `${baseUrl}/Home.jpg`;

    useEffect(() => {

        const minLoadingTime = 600;
        const timeout = setTimeout(() => setMinLoadingTimeReached(true), minLoadingTime);

        if (blogs.length > 0 && minLoadingTimeReached) {
            setLoading(false);
        }


        return () => clearTimeout(timeout);
    }, [blogs, minLoadingTimeReached]);

    const handlePaginationClick = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };


    const totalPages = Math.ceil(blogs.length / itemsPerPage);


    const paginatedBlogs = blogs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const renderPagination = () => {
        const paginationItems = [];


        paginationItems.push(
            <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePaginationClick(1)}>
                1
            </Pagination.Item>
        );


        if (currentPage > 2) {
            paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }


        if (currentPage > 1 && currentPage <= totalPages - 1) {
            paginationItems.push(
                <Pagination.Item key={currentPage} active onClick={() => handlePaginationClick(currentPage)}>
                    {currentPage}
                </Pagination.Item>
            );
        }


        if (currentPage < totalPages - 1) {
            paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }


        if (totalPages > 1) {
            paginationItems.push(
                <Pagination.Item key={totalPages} active={currentPage === totalPages} onClick={() => handlePaginationClick(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return paginationItems;
    };

    if (loading) return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#ffffff", height: "100vh" }}><PulseLoader color="#C79149" /></div>;

    return (
        <div style={{ backgroundColor: "#ffffff", margin: "0", padding: "0" }}>

            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content="شركة إبراهيم الزهراني وأحمد المالكي للمحاماة هي شركة قانونية سعودية مهنية تم تأسيسها على معايير المهنية الاحترافية العالية والالتزام بالقيم الإسلامية والأنظمة المرعية. نؤمن بأن العدالة هي أساس الاستقرار والنمو في المجتمع ، ونسعى لتحقيق هذا الهدف من خلال تقديم خدمات قانونية شاملة ومتنوعة تناسب احتياجات عملائنا ضمن ايطار الاحترافية . مع فريق من المحامين ذوي الخبرة الواسعة والمعرفة المتعمقة بالقوانين المحلية والدولية، نعمل جاهدين لنكون شركاء موثوقين لعملائنا في مسيرتهم نحو تحقيق العدالة والنجاح." />
                <title>شركة ابراهيم الزهراني واحمد المالكي للمحاماة والاستشارات القانونية</title>
                <link rel="icon" href="./favicon.ico" type="image/x-icon" />
                <link rel="canonical" href="https://i-a-lawco.com/blog" />
                <link rel="icon" type="image/png" sizes="16x16" href="./favicon-16x16.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="./favicon-32x32.png" />
                <link rel="apple-touch-icon" href="./apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="192x192" href="./android-chrome-192x192.png" />
                <link rel="icon" type="image/png" sizes="512x512" href="./android-chrome-512x512.png" />
                <link rel="manifest" href="./site.webmanifest" />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="شركة ابراهيم الزهراني واحمد المالكي للمحاماة والاستشارات القانونية" />
                <meta property="og:description" content="شركة إبراهيم الزهراني وأحمد المالكي للمحاماة هي شركة قانونية سعودية مهنية تم تأسيسها على معايير المهنية الاحترافية العالية والالتزام بالقيم الإسلامية والأنظمة المرعية. نؤمن بأن العدالة هي أساس الاستقرار والنمو في المجتمع ، ونسعى لتحقيق هذا الهدف من خلال تقديم خدمات قانونية شاملة ومتنوعة تناسب احتياجات عملائنا ضمن ايطار الاحترافية . مع فريق من المحامين ذوي الخبرة الواسعة والمعرفة المتعمقة بالقوانين المحلية والدولية، نعمل جاهدين لنكون شركاء موثوقين لعملائنا في مسيرتهم نحو تحقيق العدالة والنجاح." />
                <meta property="og:image" content={imageUrl} />
                <meta property="og:url" content={baseUrl} />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="شركة ابراهيم الزهراني واحمد المالكي للمحاماة والاستشارات القانونية" />
                <meta name="twitter:description" content="شركة إبراهيم الزهراني وأحمد المالكي للمحاماة هي شركة قانونية سعودية مهنية تم تأسيسها على معايير المهنية الاحترافية العالية والالتزام بالقيم الإسلامية والأنظمة المرعية. نؤمن بأن العدالة هي أساس الاستقرار والنمو في المجتمع ، ونسعى لتحقيق هذا الهدف من خلال تقديم خدمات قانونية شاملة ومتنوعة تناسب احتياجات عملائنا ضمن ايطار الاحترافية . مع فريق من المحامين ذوي الخبرة الواسعة والمعرفة المتعمقة بالقوانين المحلية والدولية، نعمل جاهدين لنكون شركاء موثوقين لعملائنا في مسيرتهم نحو تحقيق العدالة والنجاح." />
                <meta name="twitter:image" content={imageUrl} />
                <meta name="twitter:site" content="@Mohamed12_98" />
                <meta name="twitter:creator" content="@Mohamed12_98" />
            </Helmet>

            <PageNav />
            <div id='Blogpage'>
                <div className='Blog-container'>
                    <div className='head-pg'>
                        <h1 data-aos="fade-right"><span>المدونة</span></h1>
                    </div>
                    <div className='Blog-card-container' data-aos="zoom-in">
                        {paginatedBlogs.length === 0 ? (
                            <p>لا يوجد مدونات</p>
                        ) : (
                            paginatedBlogs.map((blog) => (
                                <BlogCard
                                    id={blog.id}
                                    Pimg={blog.imageUrl}
                                    title={blog.title}
                                    content={blog.content}
                                    date={blog.timestamp ? new Date(blog.timestamp).toLocaleDateString() : 'Unknown date'}
                                />

                            ))
                        )}
                    </div>
                    {/* Pagination */}
                    {totalPages > 1 && (
                        <div className='PagiNation'>
                            <Pagination>
                                <Pagination.First onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1} />
                                {renderPagination()}
                                <Pagination.Last onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === totalPages} />
                            </Pagination>
                        </div>
                    )}
                </div>
            </div>
            <Contact />
            <PageFooter />
        </div>
    );
};

export default BlogPage;
